<!-- @format -->

<template>
  <div>
    <div role="status" class="animate-pulse bg-white py-5" v-if="loader">
      <div
        class="flex items-center justify-center my-4"
        v-for="key in 2"
        :key="key"
      >
        <div class="w-full">
          <div
            class="w-20 h-2.5 mx-4 my-2 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
          ></div>
          <div
            class="h-2.5 mx-4 py-4 bg-gray-50 rounded dark:bg-gray-400 me-3"
          ></div>
        </div>
      </div>
      <div
        class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
      ></div>
      <div
        class="h-2.5 bg-gray-50 rounded py-10 mx-4 dark:bg-gray-400 mb-2.5"
      ></div>
      <div
        class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
      ></div>
      <div
        class="h-2.5 bg-gray-50 rounded py-4 mx-4 dark:bg-gray-400 max-w-[540px]"
      ></div>
    </div>

    <div v-else class="bg-white rounded-md border border-gray-200">
      <div class="p-8">
        <div class="text-2xl font-normal mb-6 line-clamp">
          {{ singleComplianceItem?.name }}
        </div>
        <div>
          <div class="flex justify-between gap-4">
            <div class="flex flex-col w-full">
              <h1 class="text-sm mb-2">
                {{ singleComplianceItem?.compliance_calendar_type?.name }} type
              </h1>
              <input
                type="text"
                class="border-gray-200 border p-4 rounded-md disable-input"
                :placeholder="
                  singleComplianceItem?.compliance_calendar_type?.name + ' type'
                "
                :value="
                  singleComplianceItem?.sub_type?.charAt(0).toUpperCase() +
                  singleComplianceItem?.sub_type?.slice(1)
                "
                disabled
              />
            </div>
            <div
              class="flex flex-col w-full"
              v-if="
                !singleComplianceItem?.compliance_calendar_type?.name?.includes(
                  'Policies'
                )
              "
            >
              <h1 class="text-sm mb-2">
                {{ singleComplianceItem?.compliance_calendar_type?.name }}
                Occurrence
              </h1>
              <input
                type="text"
                class="border-gray-200 border p-4 rounded-md disable-input"
                placeholder="Monthly"
                :value="occurrence"
                disabled
              />
            </div>
          </div>
          <div class="mt-6">
            <h1 class="text-sm mb-2">
              {{ singleComplianceItem?.end_date ? "End Date" : "Due Date" }}
            </h1>
            <div class="border border-gray-200 rounded-md py-2 disable-input">
              <DatePicker :selectedDate="ComplianceDate" :isDisabled="true" />
            </div>
          </div>
          <div class="mt-6">
            <h1 class="text-sm mb-2">Practices</h1>
            <div
              class="flex flex-wrap border border-gray-200 rounded-md p-2 disable-input"
            >
              <div
                v-for="(practice, index) in singleComplianceItem?.practices"
                :key="index"
                class="text-white rounded-md m-2 p-2 text-xs flex max-w-xs"
                :class="
                  practice.status !== 1 && practice.status !== 4
                    ? 'inActive-tiles'
                    : 'bg-tiles'
                "
              >
                <div class="flex justify-between items-center">
                  <div class="font-normal line-clamp">
                    {{ practice?.practice_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="
              singleComplianceItem?.compliance_item_files &&
              singleComplianceItem?.compliance_item_files?.length > 0
            "
            class="border border-gray-200 bg-file flex justify-between items-center mt-8 py-3 rounded-md"
          >
            <div class="flex px-2">
              <img src="../../../../assets/icons/compliance_File.svg" />
              <div class="flex flex-col justify-center pl-3 text-sm capitalize">
                {{ singleComplianceItem?.compliance_item_files[0]?.file_name }}
                <div>
                  {{
                    moment(
                      singleComplianceItem?.compliance_item_files[0]?.created_at
                    ).format("MMM DD, YYYY")
                  }}
                </div>
              </div>
            </div>
            <div
              class="material-icons w-5 text-black pr-10 cursor-pointer"
              @click="
                downloadFile(
                  singleComplianceItem?.compliance_item_files[0]?.file,
                  fileName
                )
              "
            >
              save_alt_icon
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-6">
          <button
            class="border border-red text-red px-6 py-2 rounded-md"
            v-if="!parseInt(isArchived)"
            @click="
              discardCompliance(
                singleComplianceItem?.id,
                singleComplianceItem?.compliance_calendar_type?.name?.charAt(
                  0
                ) +
                  singleComplianceItem?.compliance_calendar_type?.name?.slice(1)
              )
            "
          >
            Discard
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import icon from "@/assets/icons/error.svg";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";
import moment from "moment";

import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
import useDownloadFile from "@/composables/useDownloadFile";

const { downloadFile } = useDownloadFile();
const store = useStore();
const router = useRouter();
const loader = ref(false);
const route = useRoute();
const isArchived = ref(route.params.isArchived);
const complianceId = ref(route.params.complianceId);
const singleComplianceItem = computed(
  () => store.getters["hqComplianceCalender/getSingleComplianceItem"]
);

const occurrence = computed(() => {
  return singleComplianceItem.value?.occurrence === 1 &&
    !singleComplianceItem.value?.is_recurring
    ? "once"
    : occurrenceFormat(singleComplianceItem.value?.occurrence);
});

const ComplianceDate = computed(() => {
  return singleComplianceItem.value?.due_date
    ? singleComplianceItem.value?.due_date
    : singleComplianceItem.value?.end_date;
});

const occurrenceFormat = (occurrence) => {
  switch (occurrence) {
    case 1:
      return "One Month";
    case 2:
      return "Two Months";
    case 3:
      return "Three Months";
    case 4:
      return "Four Months";
    case 5:
      return "Five Months";
    case 6:
      return "Six Months";
    case 7:
      return "Seven Months";
    case 8:
      return "Eight Months";
    case 9:
      return "Nine Months";
    case 10:
      return "Ten Months";
    case 11:
      return "Eleven Months";
    case 12:
      return "Twelve Months";
  }
};

const fileName = computed(() => {
  if (
    singleComplianceItem.value &&
    singleComplianceItem.value?.compliance_item_files
  ) {
    const url = singleComplianceItem.value?.compliance_item_files[0]?.file;
    return url ? decodeURIComponent(url.split("/").pop()) : null;
  }
  return null;
});

const discardCompliance = (complianceId, complianceType) => {
  Swal.fire({
    width: "35%",
    iconHtml: `<img src="${icon}" />`,
    title: `Are you sure you want to discard this ${
      complianceType === "Risk Assessment"
        ? complianceType
        : complianceType === "Policies"
        ? "Policy"
        : "Audit"
    }? `,
    showCancelButton: true,
    cancelButtonText: "Cancel",
    confirmButtonText: "Confirm",
    reverseButtons: true,
    confirmButtonColor: "rgba(0, 164, 153, 1)",
    customClass: {
      title: "custom-delete-title",
      popup: "border-radius",
      content: "custom-text",
      icon: "icon-border",
      cancelButton: "custom-cancel-button",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      store
        .dispatch("hqComplianceCalender/discardComplianceItem", {
          complianceId,
        })
        .then(() => {
          Swal.fire({
            toast: true,
            position: "top-end",
            title: `${
              complianceType === "Risk Assessment"
                ? complianceType
                : complianceType === "Policies"
                ? "Policy"
                : "Audit"
            } is discarded Successfully`,
            iconHtml: `<img src="${SuccessIcon}" />`,
            width: "32em",
            customClass: {
              title: "popup-title",
              popup: "popup-border",
              content: "popup-text",
              icon: "popup-icon-border",
            },
            didClose: () => {
              router.push({
                name: "hq-monitoringOfPracticeFunctionsComplianceDashboard",
              });
            },
            timer: 2000,
            showConfirmButton: false,
            background: `rgba(225, 249, 240, 1)`,
          });
        });
    } else {
      return;
    }
  });
};

onMounted(async () => {
  loader.value = true;
  await store
    .dispatch("hqComplianceCalender/fetchSingleComplianceItem", {
      complianceId: complianceId.value,
    })
    .then(() => {
      loader.value = false;
    });
});
</script>

<style scoped>
.disable-input {
  background: rgba(231, 243, 242, 0.65);
}

.bg-file {
  background: rgba(204, 241, 238, 1);
}

.inActive-tiles {
  background: rgba(114, 114, 114, 1) !important;
}

.bg-tiles {
  background: rgba(111, 169, 169, 1);
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
